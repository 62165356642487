/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var mobileMenuBtn = function() {
          $('.hamburger').click(function(){
            $(this).toggleClass('is-active');
            $('.mobile-menu').fadeToggle();

            $('body').toggleClass('fixed');
          });
        };
        mobileMenuBtn();

        var mobileSubItem = function() {
          $('.mobile-menu .menu-item-has-children').each(function(){
            $(this).on('click',function(){
              $(this).find('.sub-menu').slideToggle(200);
            });
          });
        };
        mobileSubItem();

        var scrollMenu = function() {
            var $element = $('.header'),
            className = 'hasScrolled';

            $(document).scroll(function() {
              if ($(document).scrollTop() >= 50) {
                // user scrolled 50 pixels or more;
                // do stuff
                $element.addClass(className);
              } else {
                $element.removeClass(className);
              }
            });
        };
        scrollMenu();


        var slider = function() {

          if ( $( ".slider" ).length ) {
            $('.slider').slick({
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
              autoplay: true,
              autoplaySpeed: 4000,
            });

            $('.slider__nav-prev').click(function(){
              $('.slider').slick('slickPrev');
            });

            $('.slider__nav-next').click(function(){
              $('.slider').slick('slickNext');
            });

            // $('.slider-facilities__list-item span').each(function(){
            //   $(this).on('click', function(){
            //     var slideIndex = $(this).attr('data-slide-id');

            //     $('.slider-facilities__main').slick('slickGoTo', slideIndex);

            //   });
            // })
          }
        };

        slider();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var heroAutoHeight = function() {
          var bgHeight = $(window).height();
          $('.hero').css('height', bgHeight);
        };
        heroAutoHeight();


        var testimonialSlider = function(){

          $('.testimonial_slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
          });

        };
        testimonialSlider();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_about': {
      init: function() {
        
        var facilities_slider = function() {

          $('.slider-facilities__main').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 4000,
          });

          $('.slider-facilities__nav-prev').click(function(){
            $('.slider-facilities__main').slick('slickPrev');
          });

          $('.slider-facilities__nav-next').click(function(){
            $('.slider-facilities__main').slick('slickNext');
          });

          $('.slider-facilities__list-item span').each(function(){
            $(this).on('click', function(){
              var slideIndex = $(this).attr('data-slide-id');

              $('.slider-facilities__main').slick('slickGoTo', slideIndex);

            });
          });

        };

        facilities_slider();

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
